<template>
  <div>
    <el-card class="tableContainer">
      <div class="header">
        <div class="title">数据列表</div>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="skuId" label="头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="用户ID"> </el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="联系方式"> </el-table-column>

        <el-table-column align="center" prop="distributionLevelName" label="分销级别"> </el-table-column>
        <el-table-column align="center" prop="driveTotalIncome" label="带来收益总额"> </el-table-column>

        <el-table-column align="center" prop="teamNum" label="他的团队人数"> </el-table-column>

        <el-table-column align="center" label="邀请时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      list: []
    }
  },
  methods: {
    // 请求后端获取分销团队列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          id: this.$route.query.id
        }
      }
      this.$api.distribution
        .distribution('distributionMemberTeamList', params, this.$route.query.id)
        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
.avatar {
  width: 50px;
  height: 50px;
}
</style>
